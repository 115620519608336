.App {
  background-color: #ffffff;
}
.App > .main-app-container {
  width: 100%;
}
h2 {
  font-size: 28px !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.cursor-pointer {
  cursor: pointer !important;
}
.textFieldComponent input,
.Mui-focused .MuiInputAdornment-root {
  padding-right: 0 !important;
}
.textFieldComponent fieldset {
  border-radius: 9px !important;
}

.MuiOutlinedInput-root {
  padding-right: 0;
}
.capitalize {
  text-transform: capitalize;
}
.swal2-html-container {
  margin: 0;
  margin-top: -10px;
  font-size: 16px;
  margin-bottom: 20px;
}
.swal2-show {
  border-radius: 18px !important;
}
.swal2-actions {
  margin-bottom: 10px;
}
.swal2-title {
  font-weight: bold;
  margin: 0;
  color: black;
  font-size: 20px !important;
  margin-bottom: 20px;
}
.css-ry1ig9-MuiInputBase-root-MuiOutlinedInput-root {
  padding-right: 0 !important;
}
.swal2-modal {
  padding: 1em 0;
}
.swal2-actions {
  margin-top: 0 !important;
}
.swal2-confirm,
.swal2-cancel {
  padding: 8px 16px;

  border-radius: 100px !important;
  color: black !important;
  min-width: 80px !important;
}

.flaticon-attribute-container,
.MuiIconButton-edgeEnd,
.MuiInputAdornment-root {
  position: absolute !important;
  /* margin-right: 20px; */
  right: 5px !important;
  color: #f1f2f2;
}
.flaticon-attribute-container a {
  color: #f1f2f2;
}
textarea,
input,
button {
  box-shadow: none !important;
  outline: none;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@media screen and (max-width: 515px) {
  .our-commitment-style {
    margin: 2em;
  }
}
