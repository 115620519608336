.public-page-header-component {
  display: flex;
  justify-content: space-between;
  max-height: 120px;
  overflow: hidden;
  padding: 2em 5vw;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 101;
  .public-page-header-logo-container {
    overflow: hidden;
    max-width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    a {
      height: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
  .public-page-header-join-us-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
