.help-container {
  svg {
    > * {
    fill:var(--secondary-color) !important
    }
  }
  .help-modal {
    position: fixed;
    bottom: calc(80px + 2.5vw);
    right:  calc(20px + 1vw);
    animation: pop-swirl 1s ease forwards; /*trigger the keyframe*/
    width: 280px;
    border-radius: 14px;
    z-index: 100;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
    height: 340px;
    background-color: white;
    .help-modal-header {
      text-align: center;
      padding: 8px 0;
      font-weight: bold;
      font-size: 12px;
      text-transform: uppercase;
      background: #eeeeee;
      border-radius: 14px 14px 0px 0px;
    }
  
    .help-modal-body {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      padding: 5px 20px;
      input,
      textarea {
        margin: 10px 0;
      }
      textarea {
        border: 1px solid #d3d2d2;
        width:100%;
        border-radius:9px;
        padding:10px;
        font-size:14px;
        height: 150px;
      }
      
    }
  }
  @keyframes pop-swirl {
    0% {
      transform: scale(0) rotate(360deg);
    }
    60% {
      transform: scale(0.8) rotate(-10deg);
    }
    100% {
      transform: scale(1) rotate(0deg);
    }
  }
}
