.textfield-atom {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  &.white {
    input {
      background-color: white !important;
    }
  }
  label {
    position: absolute;
    text-transform: capitalize;
    top: 23px;
    left: 10px;
    font-size: 14px;
    line-height: 0.2;
    transition: 0.1s;
    width: fit-content;
    padding: 0 5px;
    color: #7b7979;
    z-index: 0;
    user-select: none;
    display: flex;
    &.focus {
      background-color: white;
      top: 0px;
      left: 15px;
      z-index: 2;
    }
  }
  input {
    width: 100%;
    padding: 13px;
    border: 1px solid #d3d2d2;
    border-radius: 9px;
    font-size: 12px;
    resize: none;
    background-color: #c5c3c315;

    &:focus {
      border: 1px solid var(--primary-color);
    }
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    display: none;
  }
}
div[disabled] {
  pointer-events: none;
  opacity: 0.5;
}
