.admin-menubar-category-component {
  user-select: none;
  padding: 0px 15px 0px;
  cursor: pointer;
  margin: 5px 0;
  .category-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14px 0;
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #ffffff;
    line-height: 20px;

    svg {
      height: 8px;
    }
  }

  .inactive-category {
    max-height: 0;
    transition: max-height 0.25s ease-out;
    overflow: hidden;
  }
  &:hover,
  &.active-category {
    background: #ffffff28;
    border-radius: 8px;
  }
  .active-category {
    transition: all 0.5s ease-in;
    max-height: 200px;

    .admin-menubar-bar-link-component {
      display: flex !important;
    }
  }
}
