.primary-button-component {
  .btn {
    padding: 4px 20px;
    background-color: var(--primary-color);
    position: relative;
    color: black;
    border-radius: 100px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: capitalize;
    font-size: 16px;
    line-height: 34px;
    letter-spacing: -0.02em;
    border-color: transparent;
    border: none;
    span{
      white-space: nowrap;
    }
    .children {
      display: flex;
      justify-content: center;
      align-items: center;
      white-space: nowrap;
      .small-loader-component {
        margin-right: 5px;
      }
    }
    &.primaryShade {
      background-color: rgba(var(--primary-light), 0.6);
    }
    &.redOrange {
      background-color: #ffb0a8;
    }
    &.small {
      font-size: 14px !important;
      padding: 1px 20px !important;
    }
    &.gray {
      background-color: rgba(211, 210, 210, 1);
      color: black;
      &:hover {
        background-color: rgba(211, 210, 210, 0.5);
      }
      &:active,
      &:focus {
        background-color: rgba(211, 210, 210, 1) !important;
        color: black !important;
      }
    }
    &:hover {
      background-color: var(--primary-color);
      opacity: 0.7;
      color: black !important;
    }
    &:active,
    &:focus {
      color: black !important;
      background-color: var(--primary-color) !important;
    }

    &:disabled {
      background-color: #c8ced0;
      color: #7b7979;
      cursor: inherit;
    }
  }
  .skip {
    padding: 7px 30px;
    background-color: #d3d2d2;
    color: #5b6569;
    border-radius: 100px;
    font-weight: 500;
    font-size: 20px;
    border-color: transparent;
  }
  .btn[disabled] {
    pointer-events: none;
  }
  
  @media screen and (max-width: 768px) {
    .btn {
      white-space: nowrap;
      font-size: 14px !important;
      padding: 1px 20px !important;
    }
  }
  svg{
    width:15px;
  }
}
