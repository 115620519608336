.admin-menubar-component {
  z-index: 21;
  top: 0;
  background: var(--secondary-color);
  position: sticky;
  left: 0;
  width: 255px;
  height: 100vh;
  padding: 20px 10px;

  .admin-menubar-content-container {
    padding: 4em 0;
    position: relative;
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
    .admin-menubar-list-container {
      margin-top: 3em;
    }
  }
  .logout-btn {
    display: flex;
    justify-content: center;
  }
}
