.menu-bar-logo-component {
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  pointer-events: none;
  img {
    max-width: 150px;
    max-height:100px
  }
}
