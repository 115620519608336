.sidebar-component {
  max-width: 170px;
  min-width: 170px;
  min-height: 100vh;
  text-align: center;
  background-color: var(--secondary-color);
  -webkit-transition: margin 0.25s ease-out;
  -moz-transition: margin 0.25s ease-out;
  -o-transition: margin 0.25s ease-out;
  transition: margin 0.25s ease-out;
  justify-content: space-between;
  z-index: 0;
  z-index: 101;
  position: relative;
  justify-content: center;
  .dashboard-button, .events-button{
    svg{
       > * {
        fill:white;
        stroke:none !important;
      }
     
    }
  }

  .mandatory-field {
    position: absolute;
    color: red;
    font-weight: bold;
    font-size: 18px;
    left: 24px;
    top: -4px;
    width: fit-content;
    height: fit-content;
  }
  .parent_side {
    width: 170px;

    .sidebar-sticky {
      height:87vh;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: space-between;
      position: sticky;
      top: 60px;
      .logo {
        width: 100%;
        display: flex;
        justify-content: center;
        a {
          img {
            width: 90%;
            max-height:120px;
          }
        }
      }
      .options {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 10px;
        height: fit-content;

        .nav-item {
          position: relative;
          text-align: center;
          width: 100%;
          margin: 10px 0;
          cursor: pointer;
          a {
            text-decoration: none;
            img {
              width: 30px !important;
              height: 30px;
              object-fit: contain;
            }
          }
          p {
            margin-top: 10px;
            color: white;
          }

          svg {
            width: 30px;
            height: 30px;
            > * {
              stroke: white;
            }
            &.tax-reciept-icon{
              > * {
                &:first-child{
                  stroke:none !important;
                }
              }
              
              
           
            }
          }
        

          &.active {
            .dashboard-button, .events-button,.tax-reciept-button{
              svg > * {
                &:first-child{
                  stroke:none !important;
                }
                fill: var(--primary-color) !important;
              }
             
            }
            svg > * { 
              stroke: var(--primary-color);
            }
            p {
              color: var(--primary-color);
            }
          }
        }
      }
      &:last-child {
        display: flex;
        flex-direction: column;
      }
    }
  }
 
}

@media screen and (max-height: 768px) {
  .sidebar-component {
    min-width:140px;
    min-width:140px;
    .mandatory-field {
      font-size: 14px;
      left: 13px;
    }
    .parent_side {
      width: 150px;
      .sidebar-sticky {
        top: 40px;
        .logo {
          a {
            img {
              width: 80px;
            }
          }
        }
        .options {
          padding-top: 20px;
          margin-bottom: 10px;

          .nav-item {
            margin: 5px 0;
            p{
              font-size:14px;
            }
            a {
              svg {
                width: 25px;
                height: 25px;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-height: 600px) {
  .sidebar-component {
    .mandatory-field {
      font-size: 16px;
      left: 14px;
    }
    .parent_side {
      .sidebar-sticky {
        top: 25px;

        .options {
          padding-top: 10px;
          margin-bottom: 5px;
          margin-top: 5px;

          .nav-item {
            position: relative;
            text-align: center;
            width: 100%;
            margin: 5px 0;
          }
        }
      }
    }
  }
}
