.layout-component {
  height: 100%;
  display: flex;

  .app-container {
    width: 100%;

    &.logged-in {
      height: 100%;
      padding: 3em 2em 1em 2em;
      @media screen and (max-width: 768px) {
        min-height: 97vh;
        padding: 2em 0 4em 0;
      }
    }
    &.padding {
      height: 100%;
      padding: 4em 0 0em 0;
      @media screen and (max-width: 768px) {
        min-height: 97vh;
        padding: 2em 0 5em 0;
      }
    }
    &.explore-platform-header {
      .new-user-page {
        padding-top: 2.5em;
      }
    }
    &.no-header {
      padding: 0;
    }
  }
  &.cause-project {
    .no-header {
      .donation-page {
        .component {
          display: flex;
          justify-content: center;
        }
      }
    }
  }
  &.admin {
    min-height: 100vh;
    .app-container {
      width: calc(100% - 245px);
    }
  }
  &.public-pages {
    display: inherit;
  }
  .toast-campaign-errors-container {
    p {
      margin: 0;
      font-size: 14px;
      font-weight: 600;
    }
  }
}
:root {
  --secondary-color: #1f5e72;
  --primary-color: #faac32;
  --primary-light: #fccd84;
}
@at-root .unsubscribing-from-infaque-spinner {
  border-color: var(--primary-color) transparent var(--primary-color)
    transparent;
}
