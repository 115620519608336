.error-message-atom {
  margin: 5px 0;
  font-size: 11px;
  color: #ed4337;
  user-select: none;
  font-family: "Nunito Sans";
  bottom: -25px;
  &::first-letter {
    text-transform: capitalize;
  }
  &.position-absolute {
    position: absolute;
    left: 0px;
  }
}
