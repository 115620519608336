.admin-sidebar-system {
  padding: 50px 0;
  width: 20%;
  min-width: 250px;
  display: flex;
  flex-direction: column;
  position: sticky;
  background-color: var(--secondary-color);
  justify-content: space-between;
  z-index: 101;
  position: relative;
  min-height: 100vh;
  .btn-flat {
    min-height: 60px;
    color: white;
    font-size: 14px;
    border: 0;
    padding: 0 2em;
  }
  .nav-item:hover {
    background-color: rgba(255, 255, 255, 0.322);
    color: var(--secondary-color);
  }
  .parent_side {
    width: 100%;
    position: sticky;
    top: 2em;
    .sidebar-sticky {
      display: flex;
      width: 100%;
      padding: 0;
      flex-direction: column;
      justify-content: space-between;
      .logo {
        padding: 0 2em;
        width: 100%;
        height: 20%;
        display: flex;
        .role {
          font-weight: bold;
          text-transform: capitalize;
          color: white;
        }
      }
      .options {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-top: 40px;
        width: 100%;
        margin-bottom: 10px;
        height: fit-content;
        .sign-outbtn {
          width: 100%;
          display: flex;
          justify-content: center;
          margin-top: 2em;
          .primary-button-component {
            .btn {
              padding: 5px 30px;
              p {
                margin: 0;
              }
            }
          }
        }
        a {
          display: flex;
          height: fit-content;
          display: block;
          font-size: 14px;
          color: white;
          text-decoration: none;
          .nav-item {
            width: 100%;
            display: flex;

            padding: 0 2em;
            min-height: 60px;
            cursor: pointer;
            align-items: center;

            &:hover {
              background-color: rgba(255, 255, 255, 0.322);
              color: var(--secondary-color);
            }
            &.current {
              background-color: rgba(255, 255, 255, 0.322) !important;
            }
          }

          .nav-item {
            text-align: center;
            width: 100%;

            p {
              margin-top: 10px;
              color: white;

              &.orange {
                color: var(--primary-color) !important;
              }
            }
          }
        }
      }
    }
  }
}
