.button-floating-container {
    width: fit-content;
    .redBorder{
      border:1px solid #EA3525 !important;
    }
    .button-floating {
      background: #ebebeb;
      width: calc(50px  + 1vw);
      border-radius: 50%;
      border: 0;
      font-size: 25px;
      color: var(--secondary-color);
      font-weight: bold;
      position: fixed;
      z-index: 100;
      bottom: calc(20px + 1vw);
      right: calc(20px + 2vw);
      height: calc(50px  + 1vw);
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        min-width:30px;
        width:calc(25px + 2vw);
        animation: fade2 1s linear;
        &.cross-img {
          min-width:20px;
          width:calc(15px + 2vw);
          animation: fade1 1s linear;
        }
      }
      @keyframes fade1 {
        20% {
          transform: rotate(45deg);
          width: 30px;
          right: 52px;
          top: -24px;
        }
        40% {
          transform: rotate(45deg);
          width: 30px;
          left: 52px;
          top: -24px;
        }
        60% {
          transform: rotate(0deg);
          width: 40px;
          left: 0;
          top: -42px;
        }
      }
  
      @keyframes fade2 {
        20% {
          transform: rotate(-45deg);
          width: 30px;
          left: 52px;
          top: -24px;
        }
        40% {
          transform: rotate(-45deg);
          width: 30px;
          left: 52px;
          top: -24px;
        }
        60% {
          transform: rotate(0deg);
          width: 50px;
          left: 0;
          top: -42px;
        }
      }
    }
  }
  