.admin-menubar-bar-link-component {
  display: flex;
  cursor: pointer;
  padding: 1px 0 12px 12px;
  height: auto;

  .name {
    margin-left: 10px;
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
  }

  .logs-svg {
    width: 16px;
    height: 16px;
    margin-left: 3px;
    margin-right: 3px;
    fill: white;
  }
  .top-minus {
    margin-top: -3px;
  }

  .top-plus {
    margin-top: 3px;
  }

  .color-white-using-path {
    :first-child {
      stroke: white;
    }
    :last-child {
      fill: white;
    }
  }

  &.active-link {
    .logs-svg {
      fill: var(--primary-color);
    }
    .name {
      color: var(--primary-color);
    }

    path {
      fill: var(--primary-color);
    }
    .stroke path {
      fill: none;
      // fill: var(--primary-color);
      stroke: var(--primary-color);
      stroke-width: 1.5;
    }
    .color-white-using-path {
      :first-child {
        stroke: var(--primary-color);
        fill: inherit;
      }
      :last-child {
        stroke: inherit;
        fill: var(--primary-color);
      }
    }
  }
}
