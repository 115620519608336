.mobile-sidebar-component {
  &.no-header {
    display: none;
  }
  .mobnav {
    height: 62px;
    .mobnav-container {
      padding: 1em;
      display: flex;
      justify-content: space-between;
      align-items: center;
      z-index: 101;
      position: fixed;
      width: 100%;
      background-color: white;
      box-shadow: 0 4px 6px -6px #222;

      .title {
        p {
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          color: var(--secondary-color);
          margin: 0;
        }
      }
      .icon {
        width: 30px;
        height: 30px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .menu-button {
        width: 22px;
        .menu-bars {
          font-size: 1.2em;
          background: none;
          top: 0px !important;
          right: 0px !important;
          color: var(--secondary-color);

          img {
            width: 35px;
            height: 35px;
            border-radius: 33px;
            border: 2px solid var(--primary-color);
            padding: 5px;
          }

          svg {
            fill: #1f5f72d8;
          }
        }
      }
    }
  }

  .nav-menu-container {
    &.background {
      width: 100%;
      position: fixed;
      height: 100vh;
      z-index: 999;
      background-color: #00000067;
      top: 0;
    }
    .nav-menu {
      width: 52%;
      height: 100vh;
      z-index: 999;

      justify-content: flex-end;
      position: fixed;
      top: 0;
      right: -1000px;
      display: flex;
      transition: 850ms;
      opacity: 0;
      &.active {
        right: 0;
        transition: 350ms;
        opacity: 1;
        .navbar-toggle {
          display: none;
        }
      }

      .nav-menu-items {
        position: relative;
        width:200px;;
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 0 20px !important;
        justify-content: center;
        align-items: flex-start;
        background-color: var(--secondary-color);
        li {
          list-style-type: none;
        }
        .logo-infaque {
          margin-bottom: 20px;
        }
        .logo-infaque-instance {
          width: 100%;
          text-align: left;
          margin-bottom: 20px;
          a {
            img {
              
              object-fit: contain;
              max-height:120px;
            }
          }
        }

        .nav-text {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          list-style: none;
          height: fit-content;
          padding-bottom: 20px;
          a {
            text-decoration: none;
            color: white;
            font-size: 16px;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;

            border-radius: 4px;
            &:hover {
              color: black;
              background-color: var(--secondary-color);
              text-decoration: underline;
            }
            img {
              width: 18px;
              margin-top: -4px;
              margin-right: 10px;
            }
          }
        }

        .navbar-toggle {
          background-color: var(--secondary-color);
          height: 80px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }
      }
    }
  }
}
